import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (HomeComponent.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "HomeComponent" */ '../views/HomeComponent.vue')
},
{
    path: '/byty/:id',
    name: 'section1',
    // route level code-splitting
    // this generates a separate chunk (SectionComponent.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "SectionComponent" */ '../views/HomeComponent.vue')
},
{
    path: '*',
}

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router