<template>
  <div class="container" id="app">
    <header class="blog-header py-3">
      <div class="row flex-nowrap justify-content-between align-items-center">
        <div
          class="col-12 col-xl-12 pt-1 text-center text-sm-center text-xl-left"
        >
          <a class="logo_button" href="https:\\bpmk.sk">
            <img src="./assets/bpmk_01.svg" alt="logo" width="100%" />
          </a>
        </div>
      </div>
    </header>

    <div class="nav-scroller py-1 mb-2 d-none d-sm-block">
      <nav class="nav d-flex justify-content-between">
        <router-link class="" to="/">Všetky</router-link>
        <router-link class="" to="/byty/1">Byty vo vlastníctve BPMK</router-link>
        <router-link class="" to="/byty/2" >Obecné byty</router-link>
        <router-link class="" to="/byty/3">Vlastnícke byty</router-link>
      </nav>
    </div>
    <div class="dropdown d-block d-sm-none pb-1">
      <button
        style="width: 100%"
        class="btn blueBpmkBg text-white dropdown-toggle rounded-0"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Typ bytu
      </button>
      <div
        class="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
        style="width: 100%"
      >
        <router-link class="dropdown-item text-center" to="/"
          >Všetky</router-link
        >
        <router-link class="dropdown-item text-center" to="/byty/1"
          >Byty vo vlastníctve BPMK</router-link
        >
        <router-link class="dropdown-item text-center" to="/byty/2"
          >Obecné byty</router-link
        >
        <router-link class="dropdown-item text-center" to="/byty/3"
          >Vlastnícke byty</router-link
        >
      </div>
    </div>

    <div>
      <router-view :nameApiRequest=" apimethod($route.params.id) "></router-view>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props:[''],
  data() {
    return {
      apiname :"",
      endSlicePC : "",
    };
  },
  mounted() {

  },
  watch:{

  },
  methods: {
    apimethod($id){
      if($id){
        return this.apiname = "apartments/section/" + $id;
      }else{
       return this.apiname = "apartments";
      }
    },
  },
};
</script>
